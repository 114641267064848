import styled from "styled-components";
import LogoAliat from "../../../assets/images/logo-aliat.png";

const Wrapper = styled.div`
  padding: 40px;
  text-align: center;
`;

const Subtitle = styled.h2`
  margin-bottom: 20px;
  color: #616160;
`;

const Title = styled.h1`
  margin-top: 50px;
  color: #4a529d;
`;

const Cta = styled.button`
  margin: 50px auto 50px;
  color: #ffffff;
  background-color: #dd481f;
  border: none;
  padding: 15px 30px;
  font-size: 19px;
  border-radius: 6px;
  cursor: pointer;
  display: block;
`;

const Index = ({ executeScroll }: any) => {
  return (
    <Wrapper>
      <Subtitle>¿Te interesa saber más?</Subtitle>
      <Subtitle>Déjanos tus datos para proporcionarte más detalles.</Subtitle>
      <Title>¡Claro que puedo!</Title>
      <Cta onClick={executeScroll}>Recibe más información</Cta>
      <img src={LogoAliat} alt="logo-aliat" width={270} />
    </Wrapper>
  );
};

export default Index;
