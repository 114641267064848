import { useRef } from "react";
import Box from "@mui/material/Box";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

const Index = () => {
  const myRef = useRef<any>(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <Box>
      <Helmet>
        <title>Universidades Aliat | Universidad UVG</title>
        <meta
          name="description"
          content="En UVG te preparamos para un mejor futuro estudiando una licenciatura presencial o ejecutiva."
        />
      </Helmet>
      <Header />
      <Main myRef={myRef} />
      <Footer executeScroll={executeScroll} />
    </Box>
  );
};

export default Index;
