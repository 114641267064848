import { useState } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import IconSearch from "../../../assets/images/icon-search.svg";
import ModalInfo from "../../../components/ModalInfo";
import { ZAPS } from "../../../constants/zaps";
import { MEDIA } from "../../../constants/media";
import { campusEtac } from "../../../api/careersEtac";

const Wrapper = styled.div`
  padding: 100px 40px 50px;
  & h1 {
    display: table;
    margin: auto;
    border-bottom: 4px double #e3e3e3;
    padding-bottom: 0.4em;
    color: #555555;
    font-weight: 400;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
    & h1 {
      font-size: 25px;
    }
  }
`;

const SearchIcon = styled.img`
  color: transparent;
  vertical-align: middle;
  margin-right: 7px;
  width: 20px;
`;

const Content = styled.div`
  margin: 100px 115px 50px;
  @media screen and (max-width: 768px) {
    margin: 40px 10px 20px;
  }
`;

const Card = styled.div`
  padding: 2.5em 1.5em;
  border: 1px solid #e3e3e3;
  text-align: center;
  & h3 {
    color: #555555;
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 400;
  }
  & button {
    margin-top: 15px;
    padding: 10px 20px;
    border: none;
    letter-spacing: 0.5px;
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    background-color: #00cdcf;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Index = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [campusSelected, setCampusSelected] = useState<string | null>(null);

  const campus = [
    {
      name: campusEtac[0],
    },
    {
      name: campusEtac[1],
    },
    {
      name: campusEtac[2],
    },
    {
      name: campusEtac[3],
    },
  ];

  return (
    <Wrapper>
      <h1>LICENCIATURAS Y CAMPUS</h1>
      <Content>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          {campus.map((item) => (
            <>
              <Grid item xs={12} md={4}>
                <Card>
                  <h3>CAMPUS {item.name}</h3>
                  <button
                    onClick={() => {
                      handleOpen();
                      setCampusSelected(item.name);
                    }}
                  >
                    <SearchIcon src={IconSearch} alt="icon-search" />
                    LICENCIATURAS
                  </button>
                </Card>
              </Grid>
            </>
          ))}
        </Grid>
      </Content>
      <ModalInfo
        open={open}
        handleClose={handleClose}
        campus={campusSelected}
        action={ZAPS.ETAC}
        medio={MEDIA.DETTE}
      />
    </Wrapper>
  );
};

export default Index;
