import styled from "styled-components";
import Grid from "@mui/material/Grid";
import ImageUvg from "../../../assets/images/image-uvg.png";
import ImageComillas from "../../../assets/images/comillas.png";
import Services from "../../../components/Services";
import FormUvg from "../../../components/FormUvg";
import { campusUvg, careersUvg } from "../../../api/careersUvg";
import { UNIVERSITIES } from "../../../constants/universities";
import { LEADSOURCE } from "../../../constants/leadsource";
import { MODALITY } from "../../../constants/modality";
import { CHANNEL } from "../../../constants/channel";
import { MEDIA } from "../../../constants/media";
import { INTEREST_LEVEL } from "../../../constants/interestLevel";
import { URL_TYPE } from "../../../constants/urlTypes";
import { ZAPS } from "../../../constants/zaps";
import useMediaQuery from "@mui/material/useMediaQuery";

const Content = styled.div`
  position: relative;
  background: rgb(161, 86, 6);
  background: linear-gradient(
    0deg,
    rgba(161, 86, 6, 1) 0%,
    rgba(238, 127, 6, 1) 100%
  );
  padding: 80px 120px 100px;
  & img {
    position: absolute;
    bottom: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
    & img {
      position: relative;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 80px 25px 100px;
    & img {
      width: 550px;
      height: 450px;
    }
  }
`;

const Legend = styled.div`
  padding: 40px 260px;
  background-color: #006bb5;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  & img {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
    font-size: 18px;
  }
`;

const Index = ({ myRef }: any) => {
  const isPhone = useMediaQuery("(max-width:768px)");

  return (
    <div>
      <Content ref={myRef}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={!isPhone ? 7 : 12}>
            <img src={ImageUvg} alt="image-uvg" width={!isPhone ? 700 : 300} />
          </Grid>
          <Grid item xs={!isPhone ? 5 : 12}>
            <FormUvg
              action={ZAPS.UVG}
              campus={campusUvg}
              careers={careersUvg}
              company={UNIVERSITIES.UVG}
              leadsource={LEADSOURCE.DIGITAL_MEDIA}
              medio={MEDIA.DETTE}
              canal={CHANNEL.WEB_SITES}
              modality={MODALITY.EXECUTIVE}
              interest_level={INTEREST_LEVEL.DEGREE}
              urlType={URL_TYPE.NEUTRAL}
            />
          </Grid>
        </Grid>
      </Content>
      <Legend>
        <img src={ImageComillas} alt="image-comillas" width={120} />
        <p>
          Estudia una licenciatura en UVG, que cuenta con más de 30 años
          formando a más de 33 mil profesionales y que es la 47 de las 100
          mejores universidades del país (Guía Universitaria 2020).
        </p>
      </Legend>
      <Services university="UVG" />
    </div>
  );
};

export default Index;
