import styled from "styled-components";
import UvgLogo from "../../../assets/images/logo-uvg.png";

const Wrapper = styled.div`
  padding: 20px;
  text-align: center;
`;

const Index = () => {
  return (
    <Wrapper>
      <img src={UvgLogo} alt="uvg-logo" width={200} />
    </Wrapper>
  );
};

export default Index;
