import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/notfound";
import Uvg from "./pages/uvg";
import Home from "./pages/home";
import UvgUniversity from "./pages/universidad-uvg";
import Etac from "./pages/etac";
import EtacUniversity from "./pages/universidad-etac";
import UneaUniversity from "./pages/universidad-unea";
import UtanUniversity from "./pages/universidad-utan";
import TlatelolcoUniversity from "./pages/universidad-tlatelolco";
import UneaDegree from "./pages/licenciaturas-unea";
import UtanDegree from "./pages/licenciaturas-utan";
import TlatelolcoDegree from "./pages/licenciaturas-tlatelolco";
import SuccessPage from "./pages/thanyou";
import GlobalStyle from "./styles/global";

function App() {
  return (
    <div>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/uvg" element={<Uvg />} />
        <Route path="/etac" element={<Etac />} />
        <Route path="/universidad-uvg" element={<UvgUniversity />} />
        <Route path="/universidad-etac" element={<EtacUniversity />} />
        <Route path="/universidad-unea" element={<UneaUniversity />} />
        <Route path="/universidad-utan" element={<UtanUniversity />} />
        <Route
          path="/universidad-tlatelolco"
          element={<TlatelolcoUniversity />}
        />
        <Route path="/licenciaturas-unea" element={<UneaDegree />} />
        <Route path="/licenciaturas-utan" element={<UtanDegree />} />
        <Route
          path="/licenciaturas-tlatelolco"
          element={<TlatelolcoDegree />}
        />
        <Route
          path="/:company_id/success/:username/:lead_id"
          element={<SuccessPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
