import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  text-align: center;
`;

const Index = ({ logo }: any) => {
  return (
    <Wrapper>
      <img src={logo} alt="unea-logo" width={200} />
    </Wrapper>
  );
};

export default Index;
