import { useState } from "react";
import styled from "styled-components";
import EtacLogo from "../../../assets/images/logo-etac.png";
import ModalInfo from "../../../components/ModalInfo";
import { ZAPS } from "../../../constants/zaps";
import { MEDIA } from "../../../constants/media";

const Wrapper = styled.div`
  padding: 60px 40px;
  background-color: #185675;
  & img {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
    & img {
      position: relative;
    }
  }
`;

const Title = styled.h1`
  color: #ffffff;
  border: 1px solid;
  width: 50%;
  margin: 30px auto;
  text-align: center;
  padding: 35px 15px;
  font-size: 45px;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    width: 89%;
    font-size: 25px;
  }
`;

const Subtitle = styled.h2`
  margin: 25px auto;
  color: #ffffff;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
`;

const Cta = styled.button`
  display: block;
  margin: 50px auto;
  text-align: center;
  padding: 12px 20px;
  border: none;
  background-color: #00cdcf;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
`;

const Index = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Wrapper>
      <img src={EtacLogo} alt="etac-logo" width={300} />
      <Title>¿TE GUSTARÍA ESTUDIAR UNA LICENCIATURA EN ETAC?</Title>
      <Subtitle>TENEMOS 50 AÑOS FORMANDO PROFESIONALES</Subtitle>
      <Cta onClick={handleOpen}>MÁS INFORMACIÓN</Cta>
      <ModalInfo
        open={open}
        handleClose={handleClose}
        action={ZAPS.ETAC}
        medio={MEDIA.DETTE}
      />
    </Wrapper>
  );
};

export default Index;
