import Box from "@mui/material/Box";
import Main from "./components/Main";
import Licenciaturas from "./components/Licenciaturas";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <Box>
      <Helmet>
        <title>Universidades Aliat | ETAC</title>
        <meta
          name="description"
          content="En ETAC te preparamos para un mejor futuro estudiando una licenciatura presencial o ejecutiva."
        />
      </Helmet>
      <Main />
      <Licenciaturas />
    </Box>
  );
};

export default Index;
