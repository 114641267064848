import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import Input from "./Input";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// @ts-ignore
import ZapierForm from "react-zapier-form";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

const Content = styled(Box)`
  padding: 25px 0 0;
  & h3 {
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
    font-size: 22px;
  }
`;

const Field = styled(Box)`
  margin-top: 20px;
  & label {
    display: block;
    margin-bottom: 5px;
    font-size: 12.5px;
    font-weight: 500;
    font-family: "Poppins";
    color: #ffffff;
  }
`;

interface career {
  id: string;
  career_name: string;
  campus_name: string;
}

interface formProps {
  action: string;
  campusSelected?: string | null;
  careers: Array<career>;
  campus: Array<string>;
  company: string;
  leadsource: string;
  medio: string;
  canal: string;
  modality: string;
  interest_level: string;
  urlType: string;
}

const Index = ({
  action,
  campusSelected,
  careers,
  campus,
  company,
  leadsource,
  medio,
  canal,
  modality,
  interest_level,
  urlType,
}: formProps) => {
  let navigate = useNavigate();

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("campus__c", campusSelected ? campusSelected : "");
    setValue("carrer", "");
    setValue("company", company);
    setValue("leadsource", leadsource);
    setValue("medio__c", medio);
    setValue("canal__c", canal);
    setValue("modalidad_c", modality);
    setValue("modalidad", "C");
    setValue("nivel_de_interes", interest_level);
    setValue("ciclo_de_tu_interes", "");
    setValue("id", uuidv4());
  }, []);

  const careersLegacy = careers.filter(
    (career) => career.campus_name === watch("campus__c")
  );

  const goToThankyouPage = (): any => {
    window.location.href = `/${company.toLowerCase()}/success/${
      watch("firstname").split(" ")[0]
    }/${watch("id")}-${urlType}`;
  };

  const [enableButton, setEnableButton] = useState(false);

  useEffect(() => {
    if (
      watch("campus__c").length &&
      watch("carrer").length &&
      watch("firstname").length &&
      watch("lastname").length &&
      watch("email").length &&
      watch("mobilephone").length
    ) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [
    watch("campus__c"),
    watch("carrer"),
    watch("firstname"),
    watch("lastname"),
    watch("email"),
    watch("mobilephone"),
  ]);

  return (
    <Content>
      <ZapierForm action={action}>
        {({ error, loading, success }: any) => {
          return (
            <div>
              {!success && !loading && (
                <div>
                  <Field>
                    <Controller
                      name="campus__c"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Box>
                          <FormControl fullWidth>
                            <Select
                              {...field}
                              displayEmpty
                              value={watch("campus__c") || ""}
                            >
                              <MenuItem value="" disabled>
                                Seleccióna un campus
                              </MenuItem>
                              {campus.map((campus) => (
                                <MenuItem value={campus}>{campus}</MenuItem>
                              ))}
                            </Select>
                            <Typography
                              variant="caption"
                              sx={{ color: "#F79540" }}
                            >
                              {errors.campus__c?.type === "required" &&
                                "* Por favor selecciona una opción"}
                            </Typography>
                          </FormControl>
                        </Box>
                      )}
                    />
                  </Field>
                  <Field>
                    <Controller
                      name="carrer"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Box>
                          <FormControl fullWidth>
                            <Select
                              {...field}
                              displayEmpty
                              value={watch("carrer") || ""}
                              disabled={watch("campus__c") ? false : true}
                            >
                              <MenuItem value="" disabled>
                                Seleccióna una carrera
                              </MenuItem>
                              {careersLegacy.map((career) => (
                                <MenuItem value={career.career_name}>
                                  {career.career_name}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography
                              variant="caption"
                              sx={{ color: "#F79540" }}
                            >
                              {errors.carrer?.type === "required" &&
                                "* Por favor selecciona una opción"}
                            </Typography>
                          </FormControl>
                        </Box>
                      )}
                    />
                  </Field>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: "none" }}>
                        <Field>
                          <Input
                            type="text"
                            register={register}
                            errors={errors}
                            keyName="company"
                          />
                          <Input
                            type="text"
                            register={register}
                            errors={errors}
                            keyName="leadsource"
                          />
                          <Input
                            type="text"
                            register={register}
                            errors={errors}
                            keyName="medio__c"
                          />
                          <Input
                            type="text"
                            register={register}
                            errors={errors}
                            keyName="canal__c"
                          />
                          <Input
                            type="text"
                            register={register}
                            errors={errors}
                            keyName="modalidad_c"
                          />
                          <Input
                            type="text"
                            register={register}
                            errors={errors}
                            keyName="nivel_de_interes"
                          />
                          <Input
                            type="text"
                            register={register}
                            errors={errors}
                            keyName="ciclo_de_tu_interes"
                          />
                          <Input
                            type="text"
                            register={register}
                            errors={errors}
                            keyName="id"
                          />
                        </Field>
                      </Box>
                      <Field>
                        <Input
                          type="text"
                          register={register}
                          errors={errors}
                          keyName="firstname"
                          placeholder="Nombre(s)"
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field>
                        <Input
                          type="text"
                          register={register}
                          errors={errors}
                          keyName="lastname"
                          placeholder="Apellidos"
                        />
                      </Field>
                    </Grid>
                  </Grid>
                  <Field>
                    <Input
                      type="text"
                      register={register}
                      errors={errors}
                      keyName="email"
                      placeholder="Correo electronico"
                    />
                  </Field>
                  <Field>
                    <Input
                      type="text"
                      register={register}
                      errors={errors}
                      keyName="mobilephone"
                      placeholder="Teléfono"
                    />
                  </Field>
                  <Field>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Acepto Políticas de Privacidad"
                    />
                  </Field>
                  <Box mt={2}>
                    <LoadingButton
                      sx={{
                        backgroundColor: "#00cdcf",
                        color: "#fff",
                      }}
                      size="medium"
                      color="primary"
                      type="submit"
                      loading={loading}
                      variant="contained"
                      disabled={!enableButton}
                    >
                      <span>Enviar</span>
                    </LoadingButton>
                  </Box>
                </div>
              )}
              {error && (
                <div>Something went wrong. Please try again later.</div>
              )}
              {/* // @ts-ignore */}
              {success && <div>{goToThankyouPage()}</div>}
            </div>
          );
        }}
      </ZapierForm>
    </Content>
  );
};

export default Index;
