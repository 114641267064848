import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LogoAliat from "../..//assets/images/logo-aliat.png";
import { URL_TYPE } from "../../constants/urlTypes";
import { useParams, useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  padding: 20px;
  text-align: center;
  background: radial-gradient(
    circle,
    rgba(148, 66, 226, 0.1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  height: calc(100vh - 60px);
`;

const Logo = styled.img`
  display: block;
  margin: 30px auto 50px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 35px;
`;

const Index = () => {
  let navigate = useNavigate();
  let { company_id, username, lead_id }: any = useParams();

  const capitalizeName = username.charAt(0).toUpperCase() + username.slice(1);

  return (
    <Wrapper>
      <Logo src={LogoAliat} alt="logo-aliat" width={300} />
      <Box mt={3}>
        <Box>
          <h1>🎉🎊👏🏻</h1>
        </Box>
        <Title>Gracias por tu registro {capitalizeName}</Title>
        <p>Pronto nos contactaremos contigo</p>
        <Button
          onClick={() =>
            navigate(
              lead_id.split("").pop() === URL_TYPE.UNIVERSITY
                ? `/universidad-${company_id}`
                : lead_id.split("").pop() === URL_TYPE.NEUTRAL
                ? `/${company_id}`
                : `/licenciaturas-${company_id}`
            )
          }
          variant="contained"
          sx={{
            marginTop: "30px",
            textTransform: "initial",
            fontFamily: "Poppins",
            fontWeight: "400",
          }}
        >
          Regresar al Inicio
        </Button>
      </Box>
    </Wrapper>
  );
};

export default Index;
