import { useRef } from "react";
import UtanLogo from "../../assets/images/logo-utan.png";
import UvgTemplate from "../../templates/uvg";
import FormUvg from "../../components/FormUvg";
import { campusUtan, careersUtan } from "../../api/careersUtan";
import { LAYOUTS } from "../../constants/layouts";
import { UNIVERSITIES } from "../../constants/universities";
import { LEADSOURCE } from "../../constants/leadsource";
import { MODALITY } from "../../constants/modality";
import { CHANNEL } from "../../constants/channel";
import { MEDIA } from "../../constants/media";
import { INTEREST_LEVEL } from "../../constants/interestLevel";
import { URL_TYPE } from "../../constants/urlTypes";
import { ZAPS } from "../../constants/zaps";

const Index = () => {
  const myRef = useRef<any>(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <UvgTemplate
      meta_title="Licenciaturas UTAN"
      meta_desc="Licenciaturas UTAN"
      logo={UtanLogo}
      form="utan licenciaturas"
      layout={LAYOUTS.PURPLE}
      myRef={myRef}
      executeScroll={executeScroll}
    >
      <FormUvg
        action={ZAPS.UTAN_DEGREE}
        campus={campusUtan}
        careers={careersUtan}
        company={UNIVERSITIES.UTAN}
        leadsource={LEADSOURCE.DIGITAL_MEDIA}
        medio={MEDIA.DETTE}
        canal={CHANNEL.WEB_SITES}
        modality={MODALITY.EXECUTIVE}
        interest_level={INTEREST_LEVEL.DEGREE}
        urlType={URL_TYPE.DEGREE}
      />
    </UvgTemplate>
  );
};

export default Index;
