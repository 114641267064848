export const ZAPS = Object.freeze({
  UVG: "https://hooks.zapier.com/hooks/catch/12940393/bnl2b7x/",
  ETAC: "https://hooks.zapier.com/hooks/catch/12940393/34862yl/",
  UNEA_DEGREE: "https://hooks.zapier.com/hooks/catch/12940393/366lwfw/",
  UTAN_DEGREE: "https://hooks.zapier.com/hooks/catch/12940393/366lv3x/",
  TLATELOLCO_DEGREE: "https://hooks.zapier.com/hooks/catch/12940393/366eby6/",
  UNEA_UNIVERSITY: "https://hooks.zapier.com/hooks/catch/12940393/366euug/",
  UTAN_UNIVERSITY: "https://hooks.zapier.com/hooks/catch/12940393/366e67s/",
  TLATELOLCO_UNIVERSITY:
    "https://hooks.zapier.com/hooks/catch/12940393/366em4o/",
  UVG_UNIVERSITY: "https://hooks.zapier.com/hooks/catch/12940393/366e9wz/",
  ETAC_UNIVERSITY: "https://hooks.zapier.com/hooks/catch/12940393/366n7wa/",
});
