/* eslint-disable jsx-a11y/img-redundant-alt */
import styled, { css } from "styled-components";
import Grid from "@mui/material/Grid";
import ImagePerson from "../../../assets/images/image1.png";
import ImageComillas from "../../../assets/images/comillas.png";
import Services from "../../../components/Services";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LAYOUTS } from "../../../constants/layouts";

interface contentProps {
  layout: string;
}

const Content = styled.div<contentProps>`
  position: relative;
  ${({ layout }) =>
    layout === LAYOUTS.PURPLE
      ? css`
          background: rgb(74, 82, 157);
          background: linear-gradient(
            180deg,
            rgba(74, 82, 157, 1) 54%,
            rgba(43, 47, 91, 1) 100%
          );
        `
      : css`
          background: rgb(226, 78, 66);
          background: linear-gradient(
            180deg,
            rgba(226, 78, 66, 1) 54%,
            rgba(111, 38, 32, 1) 100%
          );
        `}
  padding: 80px 120px 100px;
  & img {
    position: absolute;
    bottom: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
    & img {
      position: relative;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 80px 25px 100px;
    & img {
      width: 550px;
      height: 450px;
    }
  }
`;

const Legend = styled.div`
  padding: 40px 260px;
  background-color: #006bb5;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  & img {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
    font-size: 18px;
  }
`;

interface mainProps {
  children?: any;
  form: string;
  layout: string;
  myRef: any;
}

const Index = ({ children, layout, myRef, form }: mainProps) => {
  const isPhone = useMediaQuery("(max-width:768px)");

  const university: string = form.split(" ")[0].toLocaleUpperCase();

  return (
    <div>
      <Content ref={myRef} layout={layout}>
        <Grid container alignItems="center" spacing={!isPhone ? 15 : 0}>
          <Grid item xs={!isPhone ? 7 : 12}>
            <img
              src={ImagePerson}
              alt="image-person"
              width={!isPhone ? 700 : 300}
            />
          </Grid>
          <Grid item xs={!isPhone ? 5 : 12}>
            {children}
          </Grid>
        </Grid>
      </Content>
      <Legend>
        <img src={ImageComillas} alt="image-comillas" width={120} />
        <p>
          Estudia una licenciatura en {university}, que cuenta con más de 30
          años formando a más de 33 mil profesionales y que es la 47 de las 100
          mejores universidades del país (Guía Universitaria 2020).
        </p>
      </Legend>
      <Services university={university} />
    </div>
  );
};

export default Index;
