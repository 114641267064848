import { useRef } from "react";
import UneaLogo from "../../assets/images/unea.png";
import UvgTemplate from "../../templates/uvg";
import FormUvg from "../../components/FormUvg";
import { campusUnea, careersUnea } from "../../api/careersUnea";
import { LAYOUTS } from "../../constants/layouts";
import { UNIVERSITIES } from "../../constants/universities";
import { LEADSOURCE } from "../../constants/leadsource";
import { MODALITY } from "../../constants/modality";
import { CHANNEL } from "../../constants/channel";
import { MEDIA } from "../../constants/media";
import { INTEREST_LEVEL } from "../../constants/interestLevel";
import { URL_TYPE } from "../..//constants/urlTypes";
import { ZAPS } from "../../constants/zaps";

const Index = () => {
  const myRef = useRef<any>(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <UvgTemplate
      meta_title="Universidad UNEA"
      meta_desc="Universidad UNEA"
      logo={UneaLogo}
      form="unea universidad"
      layout={LAYOUTS.RED}
      myRef={myRef}
      executeScroll={executeScroll}
    >
      <FormUvg
        action={ZAPS.UNEA_UNIVERSITY}
        campus={campusUnea}
        careers={careersUnea}
        company={UNIVERSITIES.UNEA}
        leadsource={LEADSOURCE.DIGITAL_MEDIA}
        medio={MEDIA.BEDIGITAL}
        canal={CHANNEL.WEB_SITES}
        modality={MODALITY.EXECUTIVE}
        interest_level={INTEREST_LEVEL.DEGREE}
        urlType={URL_TYPE.UNIVERSITY}
      />
    </UvgTemplate>
  );
};

export default Index;
