import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Icono1Uvg from "../assets/images/icono1-uvg.png";
import Icono2Uvg from "../assets/images/icono2-uvg.png";
import Icono3Uvg from "../assets/images/icono3-uvg.png";
import Icono4Uvg from "../assets/images/icono4-uvg.png";
import useMediaQuery from "@mui/material/useMediaQuery";

const SecondLegend = styled.div`
  margin-bottom: 60px;
  padding: 40px 150px;
  color: #ffffff;
  font-size: 22px;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
    margin-bottom: 60px;
  }
`;

const Services = styled.div`
  position: relative;
  margin-top: 170px;
  padding: 40px 40px 70px;
  background-color: #568ac7;
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
  }
`;

const ContentServices = styled.div`
  margin: -180px 105px 40px;
  @media screen and (max-width: 768px) {
    margin: -180px 45px 40px;
  }
`;

const ServiceCard = styled.div`
  height: 230px;
  padding: 30px 15px 20px;
  background-color: #e5e4e4;
  color: #616160;
  text-align: center;
  @media screen and (max-width: 768px) {
    & img {
      margin-bottom: 10px;
    }
  }
`;

const Decorative = styled.div`
  width: calc(100vw - 720px);
  height: 40px;
  background-color: #f59e21;
  margin: auto;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 50px;
    width: 155px;
    height: 10px;
    background-color: #ffffff;
    z-index: 1;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 50px;
    width: 155px;
    height: 10px;
    background-color: #ffffff;
    z-index: 1;
  }
`;

interface servicesProps {
  university: string;
}

const Index = ({ university }: servicesProps) => {
  const isPhone = useMediaQuery("(max-width:768px)");

  return (
    <Services>
      <ContentServices>
        <Grid container alignItems="center" spacing={!isPhone ? 8 : 4}>
          <Grid item xs={12} md={3}>
            <ServiceCard>
              <img src={Icono1Uvg} alt="icono-1-uvg" width={75} />
              <p>Planes de estudio con ENFOQUE A LA EMPLEABILIDAD.</p>
            </ServiceCard>
          </Grid>
          <Grid item xs={12} md={3}>
            <ServiceCard>
              <img src={Icono2Uvg} alt="icono-2-uvg" width={75} />
              <p>
                Certificaciones con enfoque a COMPETENCIAS PARA EL TRABAJO por
                carrera.
              </p>
            </ServiceCard>
          </Grid>
          <Grid item xs={12} md={3}>
            <ServiceCard>
              <img src={Icono3Uvg} alt="icono-3-uvg" width={75} />
              <p>
                Acceso a OFICINA EMPRÉNDETE que incentiva el desarrollo de
                competencias laborales a través de la PRUEBA CONÓCETE.
              </p>
            </ServiceCard>
          </Grid>
          <Grid item xs={12} md={3}>
            <ServiceCard>
              <img src={Icono4Uvg} alt="icono-4-uvg" width={75} />
              <p>
                Programas de estudio flexibles, pensando en que puedes
                distribuir tu tiempo entre el estudio y el trabajo.
              </p>
            </ServiceCard>
          </Grid>
        </Grid>
      </ContentServices>
      <SecondLegend>
        <p>
          Además, {university} es parte de la red Aliat Universidades, integrada
          por 7 instituciones que tienen presencia en 18 estados de la república
          y en la que se han formado más de 170 mil profesionistas, entre todas
          sus universidades.
        </p>
      </SecondLegend>
      <Decorative />
    </Services>
  );
};

export default Index;
