import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "@mui/material/Button";

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  & div {
    & h1 {
      color: transparent;
      font-weight: 500;
      font-size: 55px;
      background: linear-gradient(rgb(238, 127, 6), #fff);
      -webkit-background-clip: text;
      font-size: 150px;
      text-align: center;
    }
    & p {
      text-align: center;
    }
  }
`;

const ButtonBack = styled(Button)`
  margin: 30px auto !important;
  display: block !important;
  text-align: center;
  font-family: "Poppins" !important;
`;

const Index = () => {
  let navigate = useNavigate();

  return (
    <Content>
      <div>
        <h1>404</h1>
        <p>¡Página no encontrada!</p>
        <ButtonBack onClick={() => navigate("/uvg")} variant="contained">
          Ir al inicio
        </ButtonBack>
      </div>
    </Content>
  );
};

export default Index;
