import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormEtac from "../components/FormEtac";
import IconoClose from "../assets/images/icono-close.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { campusEtac, careersEtac } from "../api/careersEtac";
import { UNIVERSITIES } from "../constants/universities";
import { LEADSOURCE } from "../constants/leadsource";
import { MODALITY } from "../constants/modality";
import { CHANNEL } from "../constants/channel";
import { INTEREST_LEVEL } from "../constants/interestLevel";
import { URL_TYPE } from "../constants/urlTypes";

const Title = styled.h3`
  text-align: center;
  color: #fff;
  font-weight: 400;
`;

const ButtonClose = styled.button`
  position: absolute;
  right: 0;
  top: -37px;
  font-size: 20px;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
`;

interface modalProps {
  action: string;
  medio: string;
  open: boolean;
  handleClose: () => void;
  campus?: string | null;
}

const Index = ({ action, medio, open, handleClose, campus }: modalProps) => {
  const isPhone = useMediaQuery("(max-width:768px)");

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isPhone ? 300 : 450,
    bgcolor: "#185675",
    borderStyle: "double solid",
    borderWidth: "4px 1px",
    borderColor: "#fff",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ButtonClose onClick={handleClose}>
          <img src={IconoClose} alt="icono-close" width={25} />
        </ButtonClose>
        <Title>¿Que te gustaría estudiar? {campus} </Title>
        <FormEtac
          action={action}
          campusSelected={campus}
          campus={campusEtac}
          careers={careersEtac}
          company={UNIVERSITIES.ETAC}
          leadsource={LEADSOURCE.DIGITAL_MEDIA}
          medio={medio}
          canal={CHANNEL.WEB_SITES}
          modality={MODALITY.EXECUTIVE}
          interest_level={INTEREST_LEVEL.DEGREE}
          urlType={URL_TYPE.NEUTRAL}
        />
      </Box>
    </Modal>
  );
};

export default Index;
