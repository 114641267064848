import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
    html {
        scroll-behavior: smooth;
    }
    body {
    margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    p,
    a,
    button, li, a, span, small {
    margin: 0;
    font-family: "Poppins";
    }

    a {
    color: #000;
    text-decoration: none;
    }

    button .gm-ui-hover-effect {
    margin: 5px !important;
    }

    textarea::placeholder {
    color: #aab5be;
    padding-bottom: 10px;
    }
    .css-qiwgdb.css-qiwgdb.css-qiwgdb {
        background-color: #fff !important;
    }
    .css-yu4dhi-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
        background-color: rgba(25, 118, 210, 0.5) !important;
    }
    .css-1yt7yx7-MuiLoadingButton-loadingIndicator {
        color: #ffffff !important;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 12px 14px !important;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        font-family: 'Poppins' !important;
        font-weight: 400 !important;
        background-color: #ffffff !important;
    }  
    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        font-weight: 400 !important;
        color: #A2A2A2 !important;
    }
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        font-family: 'Poppins' !important;
        font-size: 13px !important;
    }
    .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        position: relative !important;
        top: 2px !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        font-family: 'Poppins' !important;
    }
    .css-qiwgdb.css-qiwgdb.css-qiwgdb {
        font-family: 'Poppins' !important;
        font-size: 13px !important;
    }
`;
