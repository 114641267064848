import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { GTM } from "./constants/gtm";

function getCurrentURL() {
  return window.location.href;
}

// Example
const url = getCurrentURL();

const tagManagerArgsBeDigital = {
  gtmId: GTM.BEDIGITAL,
};

const tagManagerArgsDette = {
  gtmId: GTM.DETTE,
};

const urlType = url.split("").pop();

if (url.includes("universidad") || urlType?.includes("u")) {
  TagManager.initialize(tagManagerArgsBeDigital);
} else {
  TagManager.initialize(tagManagerArgsDette);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
