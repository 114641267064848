export const careersUnea = [
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Aguascalientes",
  },
  {
    id: "32906",
    career_name: "Ciencias de la Educación",
    campus_name: "Aguascalientes",
  },
  {
    id: "81333",
    career_name: "Contaduría Pública",
    campus_name: "Aguascalientes",
  },
  {
    id: "81333-",
    career_name: "Derecho",
    campus_name: "Aguascalientes",
  },
  {
    id: "81333-",
    career_name: "Psicología",
    campus_name: "Aguascalientes",
  },
  {
    id: "81333-",
    career_name: "Nivelación de técnico a lic. en enfermería",
    campus_name: "Aguascalientes",
  },
  {
    id: "81333-",
    career_name: "Administración de Empresas",
    campus_name: "Alamar",
  },
  {
    id: "81333-",
    career_name: "Ciencias de la Educación",
    campus_name: "Alamar",
  },
  {
    id: "81333-",
    career_name: "Contaduría Pública",
    campus_name: "Alamar",
  },
  {
    id: "81333-",
    career_name: "Derecho",
    campus_name: "Alamar",
  },
  {
    id: "22103",
    career_name: "Ingeniería Industrial",
    campus_name: "Alamar",
  },
  {
    id: "22103",
    career_name: "Psicología",
    campus_name: "Alamar",
  },
  {
    id: "09987",
    career_name: "Administración de Empresas",
    campus_name: "Chihuahua",
  },
  {
    id: "09987",
    career_name: "Derecho",
    campus_name: "Chihuahua",
  },
  {
    id: "09987",
    career_name: "Ingeniería Industrial",
    campus_name: "Chihuahua",
  },
  {
    id: "09987",
    career_name: "Psicología",
    campus_name: "Chihuahua",
  },
  {
    id: "09987",
    career_name: "Comercio Internacional",
    campus_name: "Florido",
  },
  {
    id: "09987",
    career_name: "Ingeniería Industrial",
    campus_name: "Florido",
  },
  {
    id: "09987",
    career_name: "Ingeniería Mecatrónica",
    campus_name: "Florido",
  },
  {
    id: "09987",
    career_name: "Pedagogía",
    campus_name: "Florido",
  },
  {
    id: "09987",
    career_name: "Administración de Empresas",
    campus_name: "Mexicali/Cuauhtémoc",
  },
  {
    id: "09987",
    career_name: "Ciencias de la Educación",
    campus_name: "Mexicali/Cuauhtémoc",
  },
  {
    id: "09987",
    career_name: "Criminalística",
    campus_name: "Mexicali/Cuauhtémoc",
  },
  {
    id: "09987",
    career_name: "Derecho",
    campus_name: "Mexicali/Cuauhtémoc",
  },
  {
    id: "09987",
    career_name: "Ingeniería Industrial",
    campus_name: "Mexicali/Cuauhtémoc",
  },
  {
    id: "09987",
    career_name: "Mercadotecnia",
    campus_name: "Mexicali/Cuauhtémoc",
  },
  {
    id: "09987",
    career_name: "Psicología",
    campus_name: "Mexicali/Cuauhtémoc",
  },
  {
    id: "09987",
    career_name: "Administración de Empresas",
    campus_name: "Querétaro",
  },
  {
    id: "09987",
    career_name: "Arquitectura",
    campus_name: "Querétaro",
  },
  {
    id: "09987",
    career_name: "Comercio Internacional",
    campus_name: "Querétaro",
  },
  {
    id: "09987",
    career_name: "Derecho",
    campus_name: "Querétaro",
  },
  {
    id: "09987",
    career_name: "Diseño Arquitectónico y de Interiores",
    campus_name: "Querétaro",
  },
  {
    id: "09987",
    career_name: "Gastronomía",
    campus_name: "Querétaro",
  },
  {
    id: "09987",
    career_name: "Ingeniería Industrial",
    campus_name: "Querétaro",
  },
  {
    id: "09987",
    career_name: "Psicología",
    campus_name: "Querétaro",
  },
  {
    id: "09987",
    career_name: "Administración de Empresas",
    campus_name: "Saltillo",
  },
  {
    id: "09987",
    career_name: "Derecho",
    campus_name: "Saltillo",
  },
  {
    id: "09987",
    career_name: "Ingeniería Industrial",
    campus_name: "Saltillo",
  },
  {
    id: "09987",
    career_name: "Psicología",
    campus_name: "Saltillo",
  },
];

export const campusUnea = [
  "Aguascalientes",
  "Alamar",
  "Chihuahua",
  "Florido",
  "Mexicali/Cuauhtémoc",
  "Querétaro",
  "Saltillo",
];
