export const careersEtac = [
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Chalco",
  },
  {
    id: "33817",
    career_name: "Ciencias de la Educación",
    campus_name: "Chalco",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Chalco",
  },
  {
    id: "33817",
    career_name: "Diseño Gráfico Digital",
    campus_name: "Chalco",
  },
  {
    id: "33817",
    career_name: "Mercadotecnia",
    campus_name: "Chalco",
  },
  {
    id: "33817",
    career_name: "Informática",
    campus_name: "Chalco",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Chalco",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Chalco",
  },
  {
    id: "33817",
    career_name: "Arquitectura",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Ciencias de la comunicacion",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Ciencias de la Educación",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Comercio Internacional",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Diseño Gráfico Digital",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Gastronomía",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Ingeniería en Redes Computacionales",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Ingeniería Industrial",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Mercadotecnia",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Plan nivelatorio de enfermería",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Coacalco",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Ciencias de la comunicacion",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Ciencias de la Educación",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Diseño Gráfico Digital",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Ingeniería en Redes Computacionales",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Ingeniería Industrial",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Mercadotecnia",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Tlalnepantla",
  },
  {
    id: "33817",
    career_name: "Arquitectura",
    campus_name: "Tulancingo",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Tulancingo",
  },
  {
    id: "33817",
    career_name: "Ciencias de la Educación",
    campus_name: "Tulancingo",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Tulancingo",
  },
  {
    id: "33817",
    career_name: "Gastronomía",
    campus_name: "Tulancingo",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Tulancingo",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Tulancingo",
  },
];

export const campusEtac = ["Chalco", "Coacalco", "Tlalnepantla", "Tulancingo"];
