export const careersUtan = [
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Huasteca",
  },
  {
    id: "32906",
    career_name: "Ciencias de la Educación",
    campus_name: "Huasteca",
  },
  {
    id: "81333",
    career_name: "Contaduría Pública",
    campus_name: "Huasteca",
  },
  {
    id: "81333-",
    career_name: "Derecho",
    campus_name: "Huasteca",
  },
  {
    id: "81333-",
    career_name: "Criminalística",
    campus_name: "Huasteca",
  },
  {
    id: "81333-",
    career_name: "Educación Física y en Deporte",
    campus_name: "Huasteca",
  },
  {
    id: "81333-",
    career_name: "Enfermería",
    campus_name: "Huasteca",
  },
  {
    id: "81333-",
    career_name: "Ingeniería en Redes Computacionales",
    campus_name: "Huasteca",
  },
  {
    id: "81333-",
    career_name: "Ingeniería Industrial",
    campus_name: "Huasteca",
  },
  {
    id: "81333-",
    career_name: "Psicología",
    campus_name: "Huasteca",
  },
  {
    id: "22103",
    career_name: "Administración de Empresas",
    campus_name: "Plantel Industrias",
  },
  {
    id: "22103",
    career_name: "Arquitectura",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Comercio Internacional",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Contaduría Pública",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Diseño Gráfico Digital",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Ingeniería Industrial",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Ingeniería Industrial Automotriz",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Ingeniería Industrial en Logística",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Ingeniería Mecatrónica",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Tecnologías de la información",
    campus_name: "Plantel Industrias",
  },
  {
    id: "09987",
    career_name: "Administración de Empresas",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Contaduría Pública",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Derecho",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Educación en la enseñanza de lenguas extranjeras",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Educación Física y en Deporte",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Enfermería",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Pedagogía",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Psicología",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Trabajo Social",
    campus_name: "Saucito",
  },
  {
    id: "09987",
    career_name: "Administración de Empresas",
    campus_name: "Tequis",
  },
  {
    id: "09987",
    career_name: "Contaduría Pública",
    campus_name: "Tequis",
  },
  {
    id: "09987",
    career_name: "Derecho",
    campus_name: "Tequis",
  },
  {
    id: "09987",
    career_name: "Mercadotecnia",
    campus_name: "Tequis",
  },
  {
    id: "09987",
    career_name: "Pedagogía",
    campus_name: "Tequis",
  },
];

export const campusUtan = [
  "Huasteca",
  "Plantel Industrias",
  "Saucito",
  "Tequis",
];
