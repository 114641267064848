import Box from "@mui/material/Box";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

interface uvgTemplateProps {
  meta_title: string;
  meta_desc: string;
  children?: any;
  logo: any;
  form: string;
  layout: string;
  myRef: any;
  executeScroll: any;
}

const Index = ({
  meta_title,
  meta_desc,
  children,
  logo,
  form,
  layout,
  myRef,
  executeScroll,
}: uvgTemplateProps) => {
  return (
    <Box>
      <Helmet>
        <title>Universidades Aliat | {meta_title}</title>
        <meta
          name="description"
          content={`En ${meta_desc} te preparamos para un mejor futuro estudiando una licenciatura presencial o ejecutiva.`}
        />
      </Helmet>
      <Header logo={logo} />
      <Main form={form} layout={layout} children={children} myRef={myRef} />
      <Footer executeScroll={executeScroll} />
    </Box>
  );
};

export default Index;
