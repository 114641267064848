export const careersUvg = [
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Campeche",
  },
  {
    id: "33817",
    career_name:
      "Ciencias de la Educación con Terminal en Psicología Educativa",
    campus_name: "Campeche",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Campeche",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Campeche",
  },
  {
    id: "33817",
    career_name: "Ingeniería en Sistemas Computacionales",
    campus_name: "Campeche",
  },
  {
    id: "33817",
    career_name: "Ingeniería Industrial",
    campus_name: "Campeche",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Campeche",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Coatzacoalcos",
  },
  {
    id: "33817",
    career_name:
      "Ciencias de la Educación con Terminal en Psicología Educativa",
    campus_name: "Coatzacoalcos",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Coatzacoalcos",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Coatzacoalcos",
  },
  {
    id: "33817",
    career_name: "Ingeniería en Sistemas Computacionales",
    campus_name: "Coatzacoalcos",
  },
  {
    id: "33817",
    career_name: "Ingeniería Industrial",
    campus_name: "Coatzacoalcos",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Coatzacoalcos",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Comitán",
  },
  {
    id: "33817",
    career_name: "Ciencias de la Educación con Terminal en Ciencias Sociales",
    campus_name: "Comitán",
  },
  {
    id: "33817",
    career_name:
      "Ciencias de la Educación con Terminal en Psicología Educativa",
    campus_name: "Comitán",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Comitán",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Comitán",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Comitán",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas Turísticas",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name:
      "Ciencias de la Educación con Terminal en Psicología Educativa",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name: "Ingeniería en Sistemas Computacionales",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name: "Ingeniería Industrial",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name: "Pedagogía",
    campus_name: "Pichucalco",
  },
  {
    id: "33817",
    career_name: "Comercio Internacional",
    campus_name: "Tapachula",
  },
  {
    id: "33817",
    career_name: "Ingeniería Industrial",
    campus_name: "Tapachula",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Tapachula",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name:
      "Ciencias de la Educación con Terminal en Psicología Educativa",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Ingeniería Civil",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Ingeniería en Sistemas Computacionales",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Ingeniería Industrial",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Mercadotecnia",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Psicología",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Trabajo Social",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Plan nivelatorio de enfermería",
    campus_name: "Tuxtla Gutierrez",
  },
  {
    id: "33817",
    career_name: "Administración de Empresas",
    campus_name: "Villahermosa",
  },
  {
    id: "33817",
    career_name:
      "Ciencias de la Educación con Terminal en Psicología Educativa",
    campus_name: "Villahermosa",
  },
  {
    id: "33817",
    career_name: "Contaduría Pública",
    campus_name: "Villahermosa",
  },
  {
    id: "33817",
    career_name: "Derecho",
    campus_name: "Villahermosa",
  },
  {
    id: "33817",
    career_name: "Diseño Gráfico Digital",
    campus_name: "Villahermosa",
  },
  {
    id: "33817",
    career_name: "Ingeniería en Mantenimiento Industrial",
    campus_name: "Villahermosa",
  },
  {
    id: "33817",
    career_name: "Ingeniería Industrial",
    campus_name: "Villahermosa",
  },
];

export const campusUvg = [
  "Campeche",
  "Coatzacoalcos",
  "Comitán",
  "Pichucalco",
  "Tapachula",
  "Tuxtla Gutierrez",
  "Villahermosa",
];
