import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Index = () => {
  let navigate = useNavigate();

  useEffect(() => {
    navigate("/uvg");
  }, []);

  return <></>;
};

export default Index;
